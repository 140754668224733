import React, { useEffect, useState } from "react";
import "../../assets/style/Style.css";
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { sidebarMenu } from "../../data";
import { Link } from "react-router-dom";
import loginFormLogo from "../../assets/image/loginFormLogo.png";

export function Sidebar() {
  const [open, setOpen] = React.useState<number | null>(null);
  const [activeLink, setActiveLink] = useState(0);
  const handleOpen = (value: number) => {
    setOpen(open === value ? null : value);
  };
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUser(JSON.parse(localStorage.getItem("token")!))
    }

  }, [])

  return (
    <aside className="h-full">
      <Card className="h-full  sidebar w-64 max-w-[20rem] shadow-xl rounded-none sm:flex hidden customXS:hidden">
        <div className="flex justify-center flex-col items-center gap-4  px-4 py-8 border-b-2">
          <img
            src={loginFormLogo}
            alt="logo"
            className="w-full object-contain"
          />
        </div>

        <div className="overflow-y-auto overflow-hidden scrollingbar">
          <List className="text-Text p-0">
            {sidebarMenu.map((item, index) => {
              return (
                (item.id == 5 && (user && user.user._id == '61227e682b816f05aa85d448')) || <Accordion
                  key={index}
                  onClick={() => setActiveLink(index)}
                  className={`rounded-none ${index === activeLink ? "bg-white " : "bg-transparent"
                    }`}
                  open={open === index}
                  icon={
                    item.subtitle && (
                      <ChevronDownIcon
                        className={`mx-auto h-5 w-5 transition-transform  ${open === index && "rotate-180"
                          } ${index === activeLink ? "text-indigo-700" : "text-Text"
                          }`}
                      />
                    )
                  }
                >
                  <div key={item.id} className="mt-0 mb-0">
                    <Link to={item.slux!}>
                      <ListItem
                        className="p-0 rounded-none"
                        selected={open === index}
                      >
                        <AccordionHeader
                          onClick={() => handleOpen(index)}
                          className={`border-b-0 p-3  rounded-none hover:bg-white `} //bgcolor
                        >
                          <ListItemPrefix
                            className={`${index === activeLink
                              ? "text-sidebarIcon"
                              : "text-Text"
                              }`}
                          >
                            <item.icon />
                          </ListItemPrefix>
                          <div
                            color="blue-gray"
                            className="mr-auto py-1 w-full text-[1rem] sidebar font-[500]"
                          >
                            <div
                              className={`${index === activeLink
                                ? "text-sidebarIcon"
                                : "text-Text"
                                }`}
                            >
                              {item.name}
                            </div>
                          </div>
                        </AccordionHeader>
                      </ListItem>
                    </Link>
                    {item.subtitle && (
                      <AccordionBody className="bg-gray-100 mt-0 mb-0 p-0 ">
                        <List className="p-0 gap-0">
                          {item.subtitle.map((subtitleItem, subtitleIndex) => (
                            <Link to={subtitleItem.slux!} key={subtitleIndex}>
                              <ListItem className="border-b-2 text-Text border-gray-200 rounded-none mb-0 mt-0 h-auto">
                                <ListItemPrefix>
                                  <ChevronRightIcon
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                {subtitleItem.name1}
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      </AccordionBody>
                    )}
                  </div>
                </Accordion>
              );
            })}
          </List>
        </div>
      </Card>
    </aside>
  );
}
